@import '../../../../../../../../assets/style/base/designTheme.scss';
@import '../../../../../../../../assets/style/base/colorsTheme.scss';

.review-and-fix {
	align-items: $object-content-review-btn-flex-direction;

	&.small-table {
		.data-table-results {
			&--grid {
				height: auto;
				min-height: 80px;
			}
		}
	}

	button {
		margin-bottom: 0;
		margin-top: 10px;
		margin-right: 5px;
	}
}
.solution-tab-cluster {
	&--parts-explanation {
		text-align: left;
		padding-bottom: 5px;
		svg {
			vertical-align: bottom;
			path {
				fill: $warnColor;
			}
		}
	}
}
