@import '../../../style/globalStyles.scss';

.status-viewer-page {
	display: flex;
	justify-content: space-around;
	margin-top: 80px;

	&--explanation {
		justify-content: space-around;
		display: flex;
		flex-direction: column;
		max-width: 400px;
		align-items: flex-start;

		&--header {
			font-size: 43px;
			line-height: 50px;
			text-align: left;
			width: 600px;
			&--wrap {
				display: flex;
				flex-direction: row;
				align-items: center;
				gap: 1em;
			}
		}

		&--subheader {
			text-align: left;
			font-weight: 300;
			line-height: 1.5em;
			font-size: 25px;
		}
	}

	&--icon {
		width: 60px;
		height: 60px;
	}

	&--image {
		width: 410px;
		height: 350px;
	}

	&--button {
		margin-top: 8% !important;
	}
}
.cancel-project {
	position: fixed;
	bottom: 5%;
	right: 5%;

	&--button {
		margin: 0 10px 0 !important;
		opacity: 0.8;

		&--text {
			margin-right: 8px;
			font-size: 16px;
			font-weight: 500;
			height: 20px;
			color: $warnColor;
		}

		svg {
			fill: $warnColor;
		}
	}
}
.progress-result {
	height: 100%;
	@include flex(center, center, column);

	> div {
		min-width: 620px;
		margin: auto;
	}

	.linear-progress-bar {
		> div {
			margin-top: 0;
			box-shadow: none;
			background: transparent;
		}

		.linear-progress-bar__info {
			width: 100%;

			.progress {
				background: $whiteColor;
			}
		}

		h2,
		.linear-progress-bar--files-indicator,
		.linear-progress-bar--progress-indicator,
		.linear-progress-bar__loader {
			display: none;
		}
	}

	.status {
		p.info,
		p.error {
			top: 0;
			max-width: 500px;
			margin: auto;
			font-size: 16px;
			margin-top: 18px;
		}

		p.info {
			text-align: left;
		}
	}
}

.no-image {
	justify-content: flex-start;
	padding-left: 10%;
	> div {
		gap: 4em;
		max-width: 60%;
	}
}

.custom-error {
	.status-viewer-page--explanation--subheader {
		text-align: center;
	}
}
