.all-clusters {
	width: 100%;

	&--grid {
		&#{&} {
			width: calc(100% - 30px); // margin 30px from left/right
			margin: 15px !important;
		}

		&--size-wrapper {
			min-height: unset;
			height: auto;
		}

		&--tile {
			padding: 1em;
			margin-bottom: -1em;
		}

		&--item {
			height: auto !important;

			&--subtitle {
				text-align: left;
			}

			&--footer {
				&#{&} {
					min-height: unset;
					height: 65px;
					padding: 25px 16px;
				}
			}
		}
	}
	&--popup {
		display: initial;

		& + .details-popup--contact-us {
			top: 35px !important;
		}
	}
	&--card {
		padding-bottom: 10px;
	}
}
.printable-clusters {
	&--wrapper {
		position: relative;
		height: 100%;
	}
	&--loader {
		top: -1px !important;
	}
}
