@import '../../../style/globalStyles.scss';
@import '../../../assets/style/base/colorsTheme.scss';

.project-parts-mapping {
	&-section {
		max-width: 100% !important;

		.data-table-header {
			padding: 0;
		}

		.form-header-wrapper {
			align-self: flex-start !important;

			p,
			h3 {
				margin-left: 0 !important;
			}
			h3 {
				font-size: 20px;
				font-weight: 500;
			}
		}
	}

	&-table {
		overflow: hidden;
		width: 100%;
		justify-items: center;
		display: grid;
		grid-template-columns: repeat(5, minmax(150px, 1fr)) !important;
		gap: 15px !important;
		min-width: 960px;
		margin: 20px 0 40px;
		position: relative;
		padding-bottom: 20px;
		max-width: 80% !important;

		& > *:nth-child(5n)::after {
			content: '';
			display: block;
			width: 200vw;
			height: 1px;
			background-color: lighten($lightGrey, 5);
			position: absolute;
			bottom: -10px;
			left: -100vw;
		}

		&-header {
			background-color: transparent;
			font-weight: bold;
		}

		&-text {
			width: 100%;
			overflow: unset !important;
			font-size: 16px;

			span {
				display: block;
				overflow: hidden;
				@include text-ellipsis();
				width: 100%;
			}
		}

		// &-dropdown {
		// 	width: 250px;
		// }

		.drawing-name-wrapper {
			> div {
				width: 100%;
				@include text-ellipsis();
				white-space: nowrap;

				&:hover {
					cursor: default;
				}
			}
		}

		> div {
			@include flex(flex-start, center, column);
			width: 100%;
			position: relative;
			min-height: 45px;
		}

		.data-table-status-bar {
			height: inherit;
			width: auto;
		}
	}

	&-buttons {
		align-items: baseline;
		justify-content: flex-end;
		width: 100%;
		gap: 20px;
	}
}
.drawing-selector-wrapper {
	position: relative;
	height: 55px;
}
.data-table-dropdown-placeholder {
	position: absolute;
	bottom: -8px;
}
