@import '../../../style/globalStyles.scss';
@import '../../../assets/style/base/colorsTheme.scss';

.parts-header {
	&--title {
		font-weight: 600;
		font-size: 20px;
		line-height: 23px;
		color: $darkTextColor;
		width: 150px;
		white-space: nowrap;
		text-align: left;

		&--advanced {
			color: $advancedText;
		}
	}

	&--remove-button {
		margin: 0 10px 0 !important;

		&--text {
			margin-left: 8px;
			height: 20px;
		}
	}

	&--search-bar {
		max-height: 32px;
	}
}

.parts-subheader {
	height: 55px !important;
	padding-bottom: 0 !important;

	&--hide {
		display: none;
	}
}

.cluster-section {
	&--subheader {
		&--icon {
			margin-right: 10px;
		}

		&--text {
			white-space: pre-wrap;
		}
	}

	&--button {
		&#{&} {
			text-transform: unset;
			margin: 0;
		}
	}
}

.search-again-info {
	display: flex;
	align-items: center;
	margin-bottom: 5px;

	svg {
		width: 20px;
		margin-right: 5px;
	}
}

.text {
	&-dark {
		color: #000000;
	}
}

.standard-cost {
	&--error-message {
		margin-top: 10px;
	}

	&--alert {
		width: 600px !important;

		&--body {
			&#{&} {
				max-height: 500px;
			}
		}
	}

	&--container {
		max-height: 210px;
		display: inline-block;
		overflow-y: auto;
		margin-top: 10px;

		&::-webkit-scrollbar {
			width: 7px;
			border-radius: 10px;
			background-color: hsla(218, 76, 15, 0.08);
		}

		&::-webkit-scrollbar-thumb {
			background-color: hsla(0, 0, 66, 0.7);
			border-radius: 10px;
		}
	}
}

.grid-list--tile {
	padding: 1em;
	height: auto !important;

	.grid-list-benefits {
		z-index: 101;
	}
}

.grid-list-benefits {
	display: flex;
	align-items: center;
	z-index: 101;
	min-width: fit-content;

	&--icon {
		width: 32px;
		height: 32px;
	}
}

.more-parts-button--container {
	@include flex;
	margin-bottom: 10px;
	height: unset !important;
}

.single-part-project {
	@include flex(flex-start, null);
	position: relative;
	padding-left: 30px;

	&--actions {
		@include flex(flex-start, null, column);
		margin-right: 10%;

		@include breakpoint(lg) {
			margin-right: 20%;
		}

		&--result {
			width: 100%;
			&--text {
				margin: 15px 0 10px;
				font-size: 20px;
				font-weight: 500;
			}

			> button {
				width: 100%;
				margin-top: 20px;
				margin-left: 0;
				margin-right: 0;
				text-transform: lowercase;

				span {
					display: inline-block;

					&::first-letter {
						text-transform: uppercase !important;
					}
				}
			}
		}

		&--activities {
			@include flex(null, null, column);
			bottom: 8px;
			width: 100%;

			button {
				&:first-of-type {
					margin-top: 0;
				}

				width: 100%;
			}
		}

		&--delete {
			&:hover {
				&#{&} {
					color: $text-regular-opac;
				}
			}

			&:focus {
				&#{&} {
					color: $text-regular-opac;
				}
			}

			&#{&} {
				background-color: #ffffff !important;
				color: $text-regular-opac;
				fill: $text-regular-opac;
				padding: 12px 30px;
				height: 48px;
				text-transform: capitalize;
				border-radius: 2px;
				border: 2px solid $text-regular-opac;
				margin: 10px 0 0 !important;
				order: 1;
			}

			&--icon {
				width: 20px;
				height: 20px;
			}
		}
	}

	&--part {
		min-width: 400px;
		width: 30%;
		align-self: end;

		&--card {
			margin: 0;

			&--size-wrapper {
				@include breakpoint(lg) {
					display: inline;

					&.project-result {
						display: inline-block;
					}
				}
			}
		}
	}
}

#grid-list {
	z-index: 111;

	& > div {
		overflow: visible !important; //override inline
	}

	&.graph-view {
		height: 60px !important; //override inline
		min-height: 60px !important; //override inline
	}
}

#general-parts-statistics {
	width: calc(100% - 30px);
	margin: 0 15px;
	z-index: 0;
	overflow: visible;

	.general-part-statistics {
		width: 100%;
		overflow: auto;

		&,
		ul {
			width: inherit;
			min-height: 400px !important; //override inline

			@include breakpoint(1440px) {
				min-height: 500px !important; //override inline
			}

			@include breakpoint(1680px) {
				min-height: 600px !important; //override inline
			}
		}

		ul {
			margin: 0 !important; //override inline
			box-shadow: none !important; //override inline

			li {
				width: inherit;
			}
		}

		.parts-pagination {
			min-height: 24px !important;
			margin-top: 24px !important;
		}

		&.all-inapplicable {
			min-height: 250px !important;
			height: auto;
			ul,
			li {
				min-height: 0 !important;
				height: auto !important;
			}
		}
	}
}

.all-inapplicable {
	font-size: 14px;
	font-weight: bold;
	text-align: center;
}

.loading-wrapper {
	z-index: 1000 !important;
	width: calc(100% - 20px) !important;
}

.quick-cad-project-results--card-result {
	.multiple-parts-loading {
		height: calc(100% - 145px) !important;
	}
}

.multiple-parts-loading {
	height: calc(100% - 70px) !important;
	bottom: 0;
	top: unset !important;
	justify-content: unset !important;
}

.main-panel-home {
	.project-analysis-block {
		position: relative;

		&__loader {
			left: -260px;
			width: 100vw !important;
		}
	}

	&.withoutMenu {
		.project-analysis-block {
			&__loader {
				left: 0;
			}
		}
	}
}

.general-part-statistics__block {
	width: 100%;
}
.failed-parts-warning {
	&--wrapper {
		@include flex(center, center, row);
		@include font-builder(16px, 400);
		gap: 15px;
		width: fit-content;
		height: 70px;
		padding: 10px 60px;
		border-radius: 8px;
		margin: 10px auto;
		background-color: $whiteBackground;
		color: $blackColor;
		box-shadow: 0px 4px 20px 0 rgba(0, 0, 0, 0.14);
		svg {
			width: 24px;
		}
	}
}
.generate-more-pc-results {
	&--radio-group {
		margin-left: 10px;
		gap: 10px;
	}
	&--radio {
		gap: 5px;
	}
	&--select-printer-btn {
		margin-left: 5px;
	}
	&--tray-size-inputs {
		position: relative;
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		gap: 8px;
		height: 58px;
		align-items: baseline;
		margin-left: 20px;
		& > div {
			margin: 0 !important;
		}
	}
	&--printer-name {
		position: absolute;
		bottom: -8px;
		font-size: 12px !important;
		font-weight: 500 !important;
	}
	&--printer-tray-selector {
		height: 58px;
		margin-left: 20px;
		display: grid;
		grid-template-columns: repeat(2, 1fr) 100px;
		gap: 8px;
		align-items: center;
		&--buttons {
			@include flex(center, flex-start, row);
			gap: 5px;
		}
	}
	&--note {
		position: absolute;
		left: 50px;
		bottom: 0;
		font-size: 12px !important;
	}
	&--alert {
		position: relative;
		padding: 24px;
		h4 {
			margin-top: 0;
		}
	}
}
.remove-cluster-part-icon {
	path {
		fill: $warnColor;
	}
}
