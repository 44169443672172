@import '../../../../assets/style/base/designTheme.scss';
@import '../../../../style/globalStyles.scss';

.in-house-printers-selector {
	@include flexible-row();
	margin: 1em;

	.select {
		margin-right: 1rem;
		width: $in-house-printers-selector-width;
	}

	.button {
		margin: 0;
	}

	.add-button {
		margin: 10px;
	}
}

.in-house-printers-other-selector {
	margin: 1em;
	display: flex;
	align-items: flex-end;

	.text-field {
		width: 16rem;
		margin-right: 0.5rem;
	}

	.number-text-field {
		width: 35px;
	}

	.plus-button {
		&--button {
			margin: 10px;
			top: 10px;
		}
		&--disabled {
			margin: 10px;
			top: 10px;
			background-color: rgba(179, 173, 173, 0.726);
			opacity: 70%;
		}
	}
}

.in-house-printers {
	&--alert {
		&--body {
			max-height: 550px !important;
		}
	}
	&--checkbox {
		margin-left: 0 !important;
		> span {
			font-size: 16px;
		}
		&--wrapper {
			@include flex(flex-start, center, column);
		}
		&--explanation {
			@include flex(center, flex-start, row);
			@include font-builder(14px, 400);
			gap: 6px;
			padding: 0 10px;
		}
	}
	&--table-row {
		height: 100px;
		&.editMode {
			input {
				text-align: center;
			}
		}
	}
}
.edit-in-house-printer {
	width: 0.9em !important;
	height: 0.9em !important;
	path {
		fill: $darkGrey;
	}
}
